<template>
  <v-layout justify-center class="mt-9">
    <v-flex xs8>
      <v-card class="ma-4">
        <v-toolbar flat>
          <v-toolbar-title>Login</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form @submit.prevent="login" class="pa-4">
          <v-text-field label="Username" v-model="username"></v-text-field>
          <br />
          <v-text-field
            label="Password"
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          ></v-text-field>
          <br />
          <div style="color:red">{{errorMessage}}</div>
          <br />
          <v-btn dark color="green" type="submit">
            Login
          </v-btn>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService.ts";
import UserService from "@/services/UserService.js";

export default {
  data() {
    return {
      username: "",
      prevUsername: "",
      password: "",
      prevPassword: "",
      error: null,
      errorMessage: "",
      showError: false,
      show: false,
    };
  },
  methods: {
    async login() {
      try {
        const response = await AuthenticationService.login({
          username: this.username,
          password: this.password,
        });
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.$store.dispatch("setProvinces", (await UserService.getProvince(response.data.user.id)).data.map(x=> x.province_id));
        this.$i18n.locale = response.data.user.language;
        //this.$store.dispatch("setLang", response.data.user.language);
        this.$router.push("/");
      } catch (error) {
        console.log(error.response);
        switch(error.response.status){
          case 403:
            this.errorMessage = "Wrong credentials. Please try again.";
            break;
          case 401:
            this.errorMessage = "Wrong credentials. Please try again.";
            break;
          default:
            this.errorMessage = "An error occured while logging in. Error " + error.response.status;
            console.log(error);
            break;
        } 
        //this.error = error.response.data.error
        
      }
    },
  },
};
</script>

<style scoped></style>
